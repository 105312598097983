/* global fbq, klaviyo, Sentry */
import { useEffect, useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import * as Sentry from "@sentry/browser";

// Utils
import { preparePhoneNumber } from "../../utils";
import { FB_EVENT_COMPLETE_REGISTRATION } from "../../constants";
import useLatest from "../../hooks/useLatest/useLatest";

export function getPhoneCode() {
  const match = window.location.search.match(/phoneCode=(.*)&?/);
  const phoneCode = match
    ? match[1]
    : window.sessionStorage.getItem("phoneCode");

  return phoneCode;
}

export function getPhoneNumber() {
  const match = window.location.search.match(/phoneNumber=(.*)&?/);
  const phoneNumber = match
    ? match[1]
    : window.sessionStorage.getItem("phoneNumber");

  return phoneNumber;
}

export function getAccessToken() {
  const match = window.location.search.match(/accessToken=(.*)&?/);
  const token = match ? match[1] : window.localStorage.getItem("accessToken");

  return token;
}

export function getRefreshToken() {
  const match = window.location.search.match(/refreshToken=(.*)&?/);
  const token = match ? match[1] : window.localStorage.getItem("refreshToken");

  return token;
}

export function getUserId() {
  const match = window.location.search.match(/userId=(.*)&?/);
  const token = match ? match[1] : window.localStorage.getItem("userId");

  return token;
}

/**
 * Send customer a code to phone
 */
export function sendCode({ phoneCode, phoneNumber, email, authVendor }) {
  return fetch(
    `${process.env.REACT_APP_API_BASE_URL}/profiles/v1/auth/customers/code${
      authVendor && authVendor !== "email" ? `/${authVendor}` : ""
    }`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: email
        ? JSON.stringify({
            phoneCode,
            phoneNumber: preparePhoneNumber(phoneNumber),
            email,
          })
        : JSON.stringify({
            phoneCode,
            phoneNumber: preparePhoneNumber(phoneNumber),
          }),
    }
  ).then((res) => {
    if (!res.ok) {
      const error = new Error(
        "There was an error sending code to phone number"
      );

      return Promise.reject(error);
    }
  });
}

/**
 * Send a customer code to email
 */
export function sendCodeToEmail(email) {
  return fetch(
    `${
      process.env.REACT_APP_API_BASE_URL
    }/profiles/v1/auth/customers/email/code?email=${encodeURIComponent(email)}`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
    }
  ).then((res) => {
    if (!res.ok) {
      const error = new Error("There was an error sending code to email");

      return Promise.reject(error);
    }
  });
}

/**
 * Send a customer code to email
 */
export function verifyEmailCode(code, email) {
  return fetch(
    `${
      process.env.REACT_APP_API_BASE_URL
    }/profiles/v1/auth/customers/email/verify?email=${encodeURIComponent(
      email
    )}&code=${code}`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
    }
  ).then(async (res) => {
    const jsonResponse = await res.json();

    if (!res.ok) {
      const error = new Error(
        jsonResponse.message
          ? jsonResponse.message
          : "There was an error verifying email code"
      );

      return Promise.reject(error);
    }

    return {
      ...jsonResponse.data,
    };
  });
}

/**
 * Send a customer login code to phone
 */
export function sendLoginCode({ phoneCode, phoneNumber, email }) {
  return fetch(
    `${process.env.REACT_APP_API_BASE_URL}/profiles/v1/auth/customers/login/send`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: email
        ? JSON.stringify({
            // phoneCode,
            // phoneNumber: preparePhoneNumber(phoneNumber),
            email,
          })
        : JSON.stringify({
            phoneCode,
            phoneNumber: preparePhoneNumber(phoneNumber),
          }),
    }
  ).then(async (res) => {
    const jsonResponse = await res.json();

    if (!res.ok) {
      const error = new Error(
        jsonResponse.message
          ? jsonResponse.message
          : "There was an error sending login code"
      );

      return Promise.reject(error);
    }
  });
}

/**
 * Customer verifies password reset code (second step for phone number verification)
 */
export function verifyPasswordResetCode(phoneCode, phoneNumber, code) {
  return fetch(
    `${process.env.REACT_APP_API_BASE_URL}/profiles/v1/auth/customers/reset/verify`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        phoneCode,
        phoneNumber: preparePhoneNumber(phoneNumber),
        code,
      }),
    }
  ).then(async (res) => {
    const jsonResponse = await res.json();

    if (!res.ok) {
      const error = new Error(
        `There was an error verifying code or code is incorrect (${code})`
      );

      return Promise.reject(error);
    }

    return {
      isValid: jsonResponse.data.verified,
      resetCode: jsonResponse.data.resetCode,
    };
  });
}

/**
 * Customer registration
 */
export function register(
  {
    firstName,
    lastName,
    password,
    phoneCode,
    phoneNumber,
    code,
    email,
    identityToken,
    authorizationCode,
    authVendor,
    migrationCode,
  },
  region = process.env.REACT_APP_REGION
) {
  return fetch(
    `${
      process.env.REACT_APP_API_BASE_URL
    }/profiles/v1/auth/customers/registration${
      identityToken
        ? authorizationCode && authVendor === "apple"
          ? "/apple"
          : `/${authVendor}`
        : ""
    }`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(
        Object.assign(
          {},
          {
            // firstName,
            // lastName,
            phoneCode,
            phoneNumber: preparePhoneNumber(phoneNumber),
            registrationCode: code,
            identityToken,
            region,
          },
          firstName && { firstName },
          lastName && { lastName },
          email && { email },
          password && { password },
          authorizationCode && { authorizationCode },
          migrationCode && { migrationCode }
        )
      ),
    }
  ).then(async (res) => {
    if (res.status === 403) {
      const errorMessage = getErrorMessage(
        res.statusText || "registration failed"
      );

      const error = new Error(errorMessage);
      error.response = res; // Attach the response for further context

      throw error;
    }

    const jsonResponse = await res.json();

    if (res.status === 400) {
      const errorMessage =
        jsonResponse.message ||
        getErrorMessage(res.statusText || "registration failed");

      const error = new Error(errorMessage);
      error.response = res; // Attach the response for further context

      throw error;
    }

    if (res.ok && jsonResponse.data.accessToken) {
      if (migrationCode) {
        window.sessionStorage.setItem("migration", "true");
      }

      return {
        ...jsonResponse,
        accessToken: jsonResponse.data.accessToken,
        refreshToken: jsonResponse.data.refreshToken,
        role: jsonResponse.data.role,
        id: jsonResponse.data.customer.id,
        firstName: jsonResponse.data.customer.firstName,
        lastName: jsonResponse.data.customer.lastName,
        phoneCode: jsonResponse.data.customer.phoneCode,
        phoneNumber: jsonResponse.data.customer.phoneNumber,
        photo: jsonResponse.data.customer.photo,
        email: jsonResponse.data.customer.email,
        emailConfirmed: jsonResponse.data.customer.emailConfirmed,
        medicalInformation: jsonResponse.data.customer.medicalInformation,
        region: jsonResponse.data.customer.region,
        city: jsonResponse.data.customer.city,
        questionnaireCompleted:
          jsonResponse.data.customer.questionnaireCompleted,
        birthday: jsonResponse.data.customer.birthday,
      };
    }
  });
}

/**
 * Customer authentication
 */
export function authenticate(phoneCode, phoneNumber, email, password) {
  return fetch(
    `${process.env.REACT_APP_API_BASE_URL}/profiles/v1/auth/customers/login`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: email
        ? JSON.stringify({ email, password })
        : JSON.stringify({
            phoneCode,
            phoneNumber: preparePhoneNumber(phoneNumber),
            password,
          }),
    }
  ).then(async (res) => {
    const jsonResponse = await res.json();

    if (res.ok) {
      return {
        ...jsonResponse,
        accessToken: jsonResponse.data.accessToken,
        role: jsonResponse.data.role,
        id: jsonResponse.data.customer.id,
        firstName: jsonResponse.data.customer.firstName,
        lastName: jsonResponse.data.customer.lastName,
        phoneCode: jsonResponse.data.customer.phoneCode,
        phoneNumber: jsonResponse.data.customer.phoneNumber,
        photo: jsonResponse.data.customer.photo,
        email: jsonResponse.data.customer.email,
        emailConfirmed: jsonResponse.data.customer.emailConfirmed,
        medicalInformation: jsonResponse.data.customer.medicalInformation,
        region: jsonResponse.data.customer.region,
        city: jsonResponse.data.customer.city,
        questionnaireCompleted:
          jsonResponse.data.customer.questionnaireCompleted,
        birthday: jsonResponse.data.customer.birthday,
      };
    }

    if (res.status === 401 || res.status === 403) {
      const errorMessage =
        jsonResponse.message ||
        getErrorMessage(res.statusText || "password or login incorrect");
      throw Error(errorMessage);
    }

    if (res.status === 404) {
      throw Error(jsonResponse.message);
    }

    return Promise.reject(jsonResponse);
  });
}

/**
 * Get customer profile
 */
export function fetchCustomer(accessToken, userId) {
  return fetch(
    `${process.env.REACT_APP_API_BASE_URL}/profiles/v1/customers/${userId}`,
    {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

/**
 * Try to login with the token
 */

/**
 * Customer updates access token. With this method we try to login with Refresh token
 */
const verifyToken = (refreshToken) => {
  const accessToken = getAccessToken();

  return fetch(
    `${process.env.REACT_APP_API_BASE_URL}/profiles/v1/auth/customers/login/update-access-token?refreshToken=${refreshToken}`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
        //Authorization: `Bearer ${accessToken}`,
      },
    }
  ).then(async (res) => {
    // Fix for `SyntaxError: Unexpected end of JSON input` when response is empty on 403,
    // so we need to check for 403 error before trying to parse it.
    // Possibly we should fix this by adding JSON response on the BE
    if (res.status === 403) {
      if (typeof Sentry !== "undefined") {
        Sentry.captureMessage(`verifyToken : ${res}`);
      }
      return {
        isValid: false,
      };
    }

    const jsonResponse = await res.json();

    if (res.status === 401 || jsonResponse.message) {
      if (typeof Sentry !== "undefined") {
        Sentry.captureMessage(`verifyToken: ${jsonResponse.message}`);
      }
      return { isValid: false, error: jsonResponse.message };
    }

    if (res.status === 422) {
      if (typeof Sentry !== "undefined") {
        Sentry.captureMessage(`Refresh token is incorrect`);
      }
      return { isValid: false, error: "Refresh token is incorrect" };
    }

    if (res.ok) {
      return {
        isValid: true,
        ...jsonResponse.data,
      };
    }
  });
};

function checkAuth(
  vendor = "apple",
  identityToken,
  authorizationCode,
  firstName,
  lastName,
  email
) {
  return fetch(
    `${process.env.REACT_APP_API_BASE_URL}/profiles/v1/auth/customers/check/${vendor}`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(
        Object.assign(
          {},
          {
            identityToken,
            // firstName,
            // lastName,
            // email
          },
          authorizationCode && { authorizationCode }
        )
      ),
    }
  ).then(async (res) => {
    const json = await res.json();

    if (!res.ok) {
      const error = new Error(
        json.message ||
          getErrorMessage(res.statusText || `check ${vendor} failed`)
      );

      if (typeof Sentry !== "undefined") {
        Sentry.captureException(error, {
          extra: {
            response: {
              status: res.status,
              statusText: res.statusText,
              headers: res.headers,
              body: json,
            },
          },
        });
      }

      return Promise.reject(error);
    }

    if (json) {
      return {
        existed: json.data.existed,
        firstName: json.data.firstName,
        lastName: json.data.lastName,
        email: json.data.email,
      };
    }
  });
}

function loginWithVendor(identityToken, authorizationCode, vendor = "apple") {
  return fetch(
    `${process.env.REACT_APP_API_BASE_URL}/profiles/v1/auth/customers/login/${vendor}`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        authorizationCode,
        identityToken,
      }),
    }
  ).then(async (res) => {
    const json = await res.json();

    if (!res.ok) {
      const errorMessage =
        json.message ||
        getErrorMessage(res.statusText || `${vendor} login failed`);
      const error = new Error(errorMessage);

      if (typeof Sentry !== "undefined") {
        // Remove `captureMessage` later
        Sentry.captureMessage(`${errorMessage} (${JSON.stringify(res)})`);
        Sentry.captureException(error, {
          extra: {
            response: {
              status: res.status,
              statusText: res.statusText,
              headers: res.headers,
              body: json,
            },
          },
        });
      }

      return Promise.reject(error);
    }

    if (json) {
      return json.data;
    }
  });
}

export function getErrorMessage(message) {
  switch (message) {
    case "wrong number":
      return "Please, check your phone number and try again";
    case "code incorrect":
      return "Code is incorrect, please try again later";
    case "registration failed":
      return "Registration failed, please try again later";
    case "password or login incorrect":
      return "Password or login is incorrect";
    case "send reset code failed":
      return "Send password reset code failed, please try again later";
    case "reset password failed":
      return "Reset password failed, please try again later";
    case "check apple failed":
      return "Something went wrong, please try again later";
    case "apple login failed":
      return "Login with Apple failed, please try again later";
    case "check google failed":
      return "Something went wrong, please try again later";
    case "google login failed":
      return "Login with Google failed, please try again later";
    case "check facebook failed":
      return "Something went wrong, please try again later";
    case "facebook login failed":
      return "Login with Facebook failed, please try again later";
    default:
      return message;
  }
}

export default function useAuth() {
  const history = useHistory();

  const [user, setUser] = useState(null);
  const [hasTriedToAutoLogin, setHasTriedToAutoLogin] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [registrationCode, setRegistrationCode] = useState();
  const [resetCode, setResetCode] = useState();
  const [migrationCode, setMigrationCode] = useState();
  const [isPasswordResetReady, setIsPasswordResetReady] = useState(false);

  const latestMigrationCode = useLatest(migrationCode);

  useEffect(() => {
    const accessToken = getAccessToken();
    const refreshToken = getRefreshToken();
    const userId = getUserId();

    if (refreshToken && userId) {
      verifyToken(refreshToken, userId)
        .then((verification) => {
          if (verification && verification.isValid) {
            setUser({
              ...verification.customer,
            });

            // Why we didn't have this before?? Will it break smth?
            setIsLoggedIn(true);

            // updateAccessToken(refreshToken);
            window.localStorage.setItem(
              "accessToken",
              verification.accessToken
            );

            // onSignIn(verification.id, window.location.pathname);

            if (process.env.NODE_ENV === "production") {
              // Set GTM user id
              window.dataLayer.push({ userId: verification.id });

              if (typeof klaviyo !== "undefined") {
                // eslint-disable-next-line no-undef
                klaviyo.identify({
                  $email: verification.email,
                  $first_name: verification.firstName,
                  $last_name: verification.lastName,
                });
              }
            }

            if (typeof Sentry !== "undefined") {
              // identify the user
              Sentry.setUser({
                id: verification.id,
                email: verification.email,
              });
            }
          } else {
            window.localStorage.removeItem("userId");
            window.localStorage.removeItem("accessToken");
            window.localStorage.removeItem("refreshToken");
          }
        })
        .then(() => setHasTriedToAutoLogin(true))
        .catch((err) => {
          console.log(err);
        });
    } else {
      setHasTriedToAutoLogin(true);
    }
  }, [history]);

  /**
   * Check whether the phone number is available before registration
   */
  const checkPhoneNumber = useCallback((phoneCode, phoneNumber) => {
    window.sessionStorage.setItem("phoneCode", phoneCode);
    window.sessionStorage.setItem("phoneNumber", phoneNumber);

    const url = `${
      process.env.REACT_APP_API_BASE_URL
    }/profiles/v1/auth/customers/phone-number/check?phoneCode=${encodeURIComponent(
      phoneCode
    )}&phoneNumber=${encodeURIComponent(preparePhoneNumber(phoneNumber))}`;

    return fetch(url, {
      headers: {
        "content-type": "application/json",
      },
    }).then(async (res) => {
      const json = await res.json();

      if (!res.ok) {
        const error = new Error("There was an error checking phone number");

        return Promise.reject(error);
      }

      if (json) {
        return json.data; //{ existed: json.data.existed };
      }
    });
  }, []);

  /**
   * Check whether the email is in DB
   */
  const checkEmail = useCallback((email) => {
    window.sessionStorage.setItem("email", email);

    return fetch(
      `${
        process.env.REACT_APP_API_BASE_URL
      }/profiles/v1/auth/customers/email/check?email=${encodeURIComponent(
        email
      )}`,
      {
        headers: {
          "content-type": "application/json",
        },
      }
    ).then(async (res) => {
      const json = await res.json();

      if (!res.ok) {
        const error = new Error("There was an error checking customer email");

        return Promise.reject(error);
      }

      if (json) {
        return json.data;
      }
    });
  }, []);

  const verifyPhoneNumber = useCallback(
    (phoneCode, phoneNumber, authVendor = "apple") => {
      return sendCode({ phoneCode, phoneNumber, authVendor }).then(
        (verification) => ({ verified: true })
      );
    },
    []
  );

  const sendEmailCode = useCallback((email) => {
    return sendCodeToEmail(email).then((verification) => ({}));
  }, []);

  const verifyEmail = useCallback((code, email) => {
    return verifyEmailCode(code, email).then((verification) => {
      if (verification.verified) {
        setRegistrationCode(verification.registrationCode);

        if (verification.migrationCode) {
          setMigrationCode(verification.migrationCode);
        }

        return verification;
      } else {
        throw new Error(getErrorMessage("code incorrect"));
      }
    });
  }, []);

  // const verifyEmailForLogin = useCallback((email) => {
  //   return sendCodeToEmail(email).then((verification) => ({ verified: true }));
  // }, []);

  /**
   * Get a login code to validate customer's phone verification (first step)
   */
  const verifyPhoneNumberOrEmailForSimpleLogin = useCallback(
    ({ phoneCode, phoneNumber, email }) => {
      return sendLoginCode({ phoneCode, phoneNumber, email }).then(
        (verification) => ({ verified: true })
      );
    },
    []
  );

  /**
   * Get a reset code after successful customer's phone verification (second step)
   */
  const verifyPhoneNumberPasswordReset = useCallback(
    (phoneCode, phoneNumber, code) => {
      setIsPasswordResetReady(false);

      return verifyPasswordResetCode(phoneCode, phoneNumber, code).then(
        (verification) => {
          if (verification.isValid) {
            setIsPasswordResetReady(true);
            setResetCode(verification.resetCode);
          } else {
            throw new Error(getErrorMessage("code incorrect"));
          }
        }
      );
    },
    []
  );

  /**
   * Customer requests for a password reset (first step phone number validation)
   */
  const sendResetCode = useCallback(({ phoneCode, phoneNumber, email }) => {
    return fetch(
      `${process.env.REACT_APP_API_BASE_URL}/profiles/v1/auth/customers/reset/code`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: email
          ? JSON.stringify({
              phoneCode,
              phoneNumber: preparePhoneNumber(phoneNumber),
              email,
            })
          : JSON.stringify({
              phoneCode,
              phoneNumber: preparePhoneNumber(phoneNumber),
            }),
      }
    ).then(async (res) => {
      const json = await res.json();

      if (res.status === 400) {
        const errorMessage =
          json.message ||
          getErrorMessage(res.statusText || "send reset code failed");
        throw Error(errorMessage);
      }

      if (!res.ok) {
        const error = new Error(
          getErrorMessage(res.statusText || "send reset code failed")
        );

        return Promise.reject(error);
      }

      return json;
    });
  }, []);

  /**
   * Customer requests for a password reset (set new password after verification)
   */
  const resetPassword = useCallback(
    ({ phoneCode, phoneNumber, email, password }) => {
      return fetch(
        `${process.env.REACT_APP_API_BASE_URL}/profiles/v1/auth/customers/reset`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body:
            phoneCode && phoneNumber
              ? JSON.stringify({
                  phoneCode,
                  phoneNumber: preparePhoneNumber(phoneNumber),
                  // email,
                  resetCode,
                  password,
                })
              : JSON.stringify({
                  phoneCode,
                  phoneNumber: preparePhoneNumber(phoneNumber),
                  email,
                  resetCode,
                  password,
                }),
        }
      ).then(async (res) => {
        const json = await res.json();

        if (res.status === 400) {
          const errorMessage =
            json.message ||
            getErrorMessage(res.statusText || "reset password failed");
          throw Error(errorMessage);
        }

        if (!res.ok) {
          const error = new Error(
            getErrorMessage(res.statusText || "reset password failed")
          );

          return Promise.reject(error);
        }

        return json;
      });
    },
    [resetCode]
  );

  /**
   * Verify whether the phone number is passed the verification process
   */
  const verifyCode = useCallback((phoneCode, phoneNumber, code, authVendor) => {
    return fetch(
      `${process.env.REACT_APP_API_BASE_URL}/profiles/v1/auth/customers/verify${
        authVendor ? `/${authVendor}` : ""
      }`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          phoneCode,
          phoneNumber: preparePhoneNumber(phoneNumber),
          code,
        }),
      }
    ).then(async (res) => {
      const jsonResponse = await res.json();
      if (!res.ok) {
        const error = new Error(
          jsonResponse.message ||
            `There was an error verifying the code or code incorrect (${code})`
        );

        return Promise.reject(error);
      }

      if (jsonResponse.data.verified) {
        setRegistrationCode(jsonResponse.data.registrationCode);
        if (jsonResponse.data.migrationCode) {
          setMigrationCode(jsonResponse.data.migrationCode);
        }

        return jsonResponse;
      } else {
        throw new Error(
          // getErrorMessage(jsonResponse.message || "code incorrect")
          jsonResponse.message
        );
      }
    });
  }, []);

  const handleSignInSuccess = (response, { phoneCode, phoneNumber, email }) => {
    const { accessToken, refreshToken, role, customer } = response.data;

    setUser({
      ...response,
      accessToken, //: response.data.accessToken,
      refreshToken, //: response.data.refreshToken,
      role, //: response.data.role,
      id: customer.id,
      firstName: customer.firstName,
      lastName: customer.lastName,
      phoneCode: customer.phoneCode,
      phoneNumber: customer.phoneNumber,
      photo: customer.photo,
      email: customer.email,
      emailConfirmed: customer.emailConfirmed,
      medicalInformation: customer.medicalInformation,
      region: customer.region,
      city: customer.city,
      questionnaireCompleted: customer.questionnaireCompleted,
      birthday: customer.birthday,
    });

    setIsLoggedIn(true);

    if (phoneCode && phoneNumber) {
      window.sessionStorage.setItem("phoneCode", phoneCode);
      window.sessionStorage.setItem("phoneNumber", phoneNumber);
    }

    window.localStorage.setItem("userId", customer.id);
    window.localStorage.setItem("accessToken", accessToken);
    window.localStorage.setItem("refreshToken", refreshToken);

    if (process.env.NODE_ENV === "production") {
      window.dataLayer.push({ userId: customer.id });

      if (typeof klaviyo !== "undefined") {
        klaviyo.identify({
          $email: customer.email,
          $first_name: customer.firstName,
          $last_name: customer.lastName,
        });
      }
    }

    if (typeof Sentry !== "undefined") {
      Sentry.setUser({
        id: customer.id,
        email: customer.email,
      });
    }
  };

  /**
   * Verify whether the phone number is passed the verification process
   */
  const verifyCodeForSimpleLogin = useCallback(
    ({ phoneCode, phoneNumber, code, email }) => {
      return fetch(
        `${process.env.REACT_APP_API_BASE_URL}/profiles/v1/auth/customers/login/verify`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: email
            ? JSON.stringify({
                phoneCode,
                phoneNumber: phoneNumber && preparePhoneNumber(phoneNumber),
                code,
                email,
              })
            : JSON.stringify({
                phoneCode,
                phoneNumber: preparePhoneNumber(phoneNumber),
                code,
              }),
        }
      ).then(async (res) => {
        const jsonResponse = await res.json();
        if (!res.ok) {
          const error = new Error(
            jsonResponse.message ||
              `There was an error verifying the code or code incorrect (${code})`
          );

          return Promise.reject(error);
        }

        if (jsonResponse.success) {
          handleSignInSuccess(jsonResponse, { phoneCode, phoneNumber, email });

          // setRegistrationCode(jsonResponse.data.registrationCode);
          // setMigrationCode(jsonResponse.data.migrationCode);

          return jsonResponse;
        } else {
          throw new Error(
            // getErrorMessage(jsonResponse.message || "code incorrect")
            jsonResponse.message
          );
        }
      });
    },
    []
  );

  /**
   * Verify whether the email is passed the verification process
   */
  const verifyEmailCodeForLogin = useCallback((code, email) => {
    return fetch(
      `${process.env.REACT_APP_API_BASE_URL}/profiles/v1/auth/customers/login/verify?email=${email}&code=${code}`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
      }
    ).then(async (res) => {
      const jsonResponse = await res.json();

      if (!res.ok) {
        const error = new Error(
          jsonResponse.message ||
            `There was an error verifying email code or code incorrect (${code})`
        );

        return Promise.reject(error);
      }

      if (jsonResponse.success) {
        handleSignInSuccess(jsonResponse, email);

        // setRegistrationCode(jsonResponse.data.registrationCode);
        // setMigrationCode(jsonResponse.data.migrationCode);

        return jsonResponse;
      } else {
        throw new Error(
          // getErrorMessage(jsonResponse.message || "code incorrect")
          jsonResponse.message
        );
      }
    });
  }, []);

  const signUp = useCallback(
    (
      firstName,
      lastName,
      password,
      phoneCode,
      phoneNumber,
      code,
      email,
      region
    ) => {
      return register(
        {
          firstName,
          lastName,
          password,
          phoneCode,
          phoneNumber,
          code,
          email,
          migrationCode: latestMigrationCode.current,
        },
        region
      ).then((response) => {
        if (response && response.id) {
          setUser({
            ...response,
          });

          setIsLoggedIn(true);
          setRegistrationCode("");

          window.localStorage.setItem("userId", response.id);
          window.localStorage.setItem("accessToken", response.accessToken);
          window.localStorage.setItem("refreshToken", response.refreshToken);

          if (process.env.NODE_ENV === "production") {
            // Set GTM user id
            window.dataLayer.push({ userId: response.id });

            if (typeof klaviyo !== "undefined") {
              // eslint-disable-next-line no-undef
              klaviyo.identify({
                $email: response.email,
                $first_name: response.firstName,
                $last_name: response.lastName,
              });
            }

            window.fbq && fbq("track", FB_EVENT_COMPLETE_REGISTRATION);
          }

          if (typeof Sentry !== "undefined") {
            // identify the user
            Sentry.setUser({
              id: response.id,
              email: response.email,
            });
          }

          return response;
        } else {
          setUser(null);
          window.localStorage.removeItem("userId");
          window.localStorage.removeItem("accessToken");
          window.localStorage.removeItem("refreshToken");

          if (typeof Sentry !== "undefined") {
            // clear the currently set user
            Sentry.setUser(null);
          }

          const error = new Error("Something went wrong, try again later.");
          error.response = response; // Attach the response for further context

          throw error;
        }

        // onSignIn(verification.id, window.location.pathname);
      });
    },
    []
  );

  const signIn = useCallback(({ phoneCode, phoneNumber, email, password }) => {
    return authenticate(phoneCode, phoneNumber, email, password)
      .then((response) => {
        handleSignInSuccess(response, phoneCode, phoneNumber, email);

        return response;

        // onSignIn(verification.id, window.location.pathname);
      })
      .catch((err) => {
        Sentry.captureMessage(`signIn failed! err #: ${JSON.stringify(err)}`);
        // Rethrow
        return Promise.reject(err);
      });
  }, []);

  const signOut = useCallback(() => {
    setUser(null);
    window.localStorage.removeItem("userId");
    window.localStorage.removeItem("accessToken");
    window.localStorage.removeItem("refreshToken");

    setIsLoggedIn(false);

    if (typeof Sentry !== "undefined") {
      // clear the currently set user
      Sentry.setUser(null);
    }

    return Promise.resolve();
  }, []);

  /**
   * Check whether the user already exists before registration with Apple
   */
  const checkAppleLogin = useCallback(
    (identityToken, authorizationCode, firstName, lastName, email) =>
      checkAuth(
        "apple",
        identityToken,
        authorizationCode,
        firstName,
        lastName,
        email
      ),
    []
  );

  /**
   * Check whether the user already exists before registration with Google
   */
  const checkGoogleLogin = useCallback(
    (identityToken, firstName, lastName, email) =>
      checkAuth("google", identityToken, null, firstName, lastName, email),
    []
  );

  /**
   * Check whether the user already exists before registration with Facebook
   */
  const checkFacebookLogin = useCallback(
    (identityToken, firstName, lastName, email) =>
      checkAuth("facebook", identityToken, null, firstName, lastName, email),
    []
  );

  const signUpWithApple = useCallback(
    (
      firstName,
      lastName,
      password,
      phoneCode,
      phoneNumber,
      code,
      email,
      identityToken,
      authorizationCode,
      region
    ) => {
      return register(
        {
          firstName,
          lastName,
          password,
          phoneCode,
          phoneNumber,
          code,
          email,
          identityToken,
          authorizationCode,
          authVendor: "apple",
          migrationCode: latestMigrationCode.current,
        },
        region
      ).then((response) => {
        if (response && response.id) {
          setUser({
            ...response,
          });

          setIsLoggedIn(true);
          setRegistrationCode("");

          window.localStorage.setItem("userId", response.id);
          window.localStorage.setItem("accessToken", response.accessToken);
          window.localStorage.setItem("refreshToken", response.refreshToken);

          if (process.env.NODE_ENV === "production") {
            // Set GTM user id
            window.dataLayer.push({ userId: response.id });

            if (typeof klaviyo !== "undefined") {
              // eslint-disable-next-line no-undef
              klaviyo.identify({
                $email: response.email,
                $first_name: response.firstName,
                $last_name: response.lastName,
              });
            }

            window.fbq && fbq("track", FB_EVENT_COMPLETE_REGISTRATION);
          }

          if (typeof Sentry !== "undefined") {
            // identify the user
            Sentry.setUser({
              id: response.id,
              email: response.email,
            });
          }

          return response;
        } else {
          setUser(null);
          window.localStorage.removeItem("userId");
          window.localStorage.removeItem("accessToken");
          window.localStorage.removeItem("refreshToken");

          if (typeof Sentry !== "undefined") {
            // clear the currently set user
            Sentry.setUser(null);
            // `register` errors we capture in index.js
            Sentry.captureException(`signUpWithApple failed`, {
              extra: {
                response,
              },
            });
          }

          throw Error("Something went wrong, try again later.");
        }

        // onSignIn(verification.id, window.location.pathname);
      });
    },
    []
  );

  const loginWithApple = useCallback(
    (identityToken, authorizationCode) =>
      loginWithVendor(identityToken, authorizationCode, "apple").then(
        (response) => {
          if (response && response.customer.id) {
            setUser({
              ...response.customer,
            });

            setIsLoggedIn(true);

            window.localStorage.setItem("userId", response.customer.id);
            window.localStorage.setItem("accessToken", response.accessToken);
            window.localStorage.setItem("refreshToken", response.refreshToken);

            if (process.env.NODE_ENV === "production") {
              // Set GTM user id
              window.dataLayer.push({ userId: response.customer.id });
            }

            return response;

            // onSignIn(verification.id, window.location.pathname);
          }
        }
      ),
    []
  );

  const signUpWithGoogle = useCallback(
    (
      firstName,
      lastName,
      // password,
      phoneCode,
      phoneNumber,
      code,
      email,
      credential,
      region
    ) => {
      return register(
        {
          firstName,
          lastName,
          phoneCode,
          phoneNumber,
          code,
          email,
          identityToken: credential,
          authVendor: "google",
          migrationCode: latestMigrationCode.current,
        },
        region
      ).then((response) => {
        if (response && response.id) {
          setUser({
            ...response,
          });

          setIsLoggedIn(true);
          setRegistrationCode("");

          window.localStorage.setItem("userId", response.id);
          window.localStorage.setItem("accessToken", response.accessToken);
          window.localStorage.setItem("refreshToken", response.refreshToken);

          if (process.env.NODE_ENV === "production") {
            // Set GTM user id
            window.dataLayer.push({ userId: response.id });

            if (typeof klaviyo !== "undefined") {
              // eslint-disable-next-line no-undef
              klaviyo.identify({
                $email: response.email,
                $first_name: response.firstName,
                $last_name: response.lastName,
              });
            }

            window.fbq && fbq("track", FB_EVENT_COMPLETE_REGISTRATION);
          }

          if (typeof Sentry !== "undefined") {
            // identify the user
            Sentry.setUser({
              id: response.id,
              email: response.email,
            });
          }

          return response;
        } else {
          setUser(null);
          window.localStorage.removeItem("userId");
          window.localStorage.removeItem("accessToken");
          window.localStorage.removeItem("refreshToken");

          if (typeof Sentry !== "undefined") {
            // clear the currently set user
            Sentry.setUser(null);
            // `register` errors we capture in index.js
            Sentry.captureException(`signUpWithGoogle failed`, {
              extra: {
                response,
              },
            });
          }

          throw Error("Something went wrong, try again later.");
        }

        // onSignIn(verification.id, window.location.pathname);
      });
    },
    []
  );

  const loginWithGoogle = useCallback(
    (identityToken) =>
      loginWithVendor(identityToken, null, "google").then((response) => {
        if (response && response.customer.id) {
          setUser({
            ...response.customer,
          });

          setIsLoggedIn(true);

          window.localStorage.setItem("userId", response.customer.id);
          window.localStorage.setItem("accessToken", response.accessToken);
          window.localStorage.setItem("refreshToken", response.refreshToken);

          if (process.env.NODE_ENV === "production") {
            // Set GTM user id
            window.dataLayer.push({ userId: response.customer.id });
          }

          return response;

          // onSignIn(verification.id, window.location.pathname);
        }
      }),
    []
  );

  const signUpWithFacebook = useCallback(
    (
      firstName,
      lastName,
      phoneCode,
      phoneNumber,
      code,
      email,
      accessToken,
      region
    ) => {
      return register(
        {
          firstName,
          lastName,
          phoneCode,
          phoneNumber,
          code,
          email,
          identityToken: accessToken,
          authVendor: "facebook",
          migrationCode: latestMigrationCode.current,
        },
        region
      ).then((response) => {
        if (response && response.id) {
          setUser({
            ...response,
          });

          setIsLoggedIn(true);
          setRegistrationCode("");

          window.localStorage.setItem("userId", response.id);
          window.localStorage.setItem("accessToken", response.accessToken);
          window.localStorage.setItem("refreshToken", response.refreshToken);

          if (process.env.NODE_ENV === "production") {
            // Set GTM user id
            window.dataLayer.push({ userId: response.id });

            if (typeof klaviyo !== "undefined") {
              // eslint-disable-next-line no-undef
              klaviyo.identify({
                $email: response.email,
                $first_name: response.firstName,
                $last_name: response.lastName,
              });
            }

            window.fbq && fbq("track", FB_EVENT_COMPLETE_REGISTRATION);
          }

          if (typeof Sentry !== "undefined") {
            // identify the user
            Sentry.setUser({
              id: response.id,
              email: response.email,
            });
          }

          return response;
        } else {
          setUser(null);
          window.localStorage.removeItem("userId");
          window.localStorage.removeItem("accessToken");
          window.localStorage.removeItem("refreshToken");

          if (typeof Sentry !== "undefined") {
            // clear the currently set user
            Sentry.setUser(null);
            // `register` errors we capture in index.js
            Sentry.captureException(`signUpWithFacebook failed`, {
              extra: {
                response,
              },
            });
          }

          throw Error("Something went wrong, try again later.");
        }

        // onSignIn(verification.id, window.location.pathname);
      });
    },
    []
  );

  const loginWithFacebook = useCallback(
    (identityToken) =>
      loginWithVendor(identityToken, null, "facebook").then((response) => {
        if (response && response.customer.id) {
          setUser({
            ...response.customer,
          });

          setIsLoggedIn(true);

          window.localStorage.setItem("userId", response.customer.id);
          window.localStorage.setItem("accessToken", response.accessToken);
          window.localStorage.setItem("refreshToken", response.refreshToken);

          if (process.env.NODE_ENV === "production") {
            // Set GTM user id
            window.dataLayer.push({ userId: response.customer.id });
          }

          return response;

          // onSignIn(verification.id, window.location.pathname);
        }
      }),
    []
  );

  const clearRegistrationCode = useCallback(() => setRegistrationCode(""));

  return {
    registrationCode,
    isPasswordResetReady,
    user,
    hasTriedToAutoLogin,
    isLoggedIn,
    setUser,
    checkPhoneNumber,
    checkEmail,
    verifyPhoneNumber,
    sendEmailCode,
    verifyEmail,
    verifyPhoneNumberOrEmailForSimpleLogin,
    verifyPhoneNumberPasswordReset,
    verifyCode,
    verifyCodeForSimpleLogin,
    verifyEmailCodeForLogin,
    // updateAccessToken,
    signUp,
    signIn,
    signOut,
    sendResetCode,
    resetPassword,
    resetCode,
    migrationCode,
    setMigrationCode,
    checkAppleLogin,
    signUpWithApple,
    loginWithApple,
    checkGoogleLogin,
    signUpWithGoogle,
    loginWithGoogle,
    checkFacebookLogin,
    signUpWithFacebook,
    loginWithFacebook,
    clearRegistrationCode,
  };
}
