import React, { useEffect, useMemo, useState } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  history,
} from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  // useElements,
} from "@stripe/react-stripe-js";
import { IntlProvider } from "react-intl";

import AppStateProvider /*, { useAppState }*/, { useAppState } from "./state";
import SignUpStateProvider from "./state/SignUpState";
import { useRegion } from "./context/RegionProvider";

import ServiceWorkerWrapper from "./components/ServiceWorkerWrapper/ServiceWorkerWrapper";

import HomepageUK from "./components/UKComponents/Homepage/Homepage";
import BecomePartnerPage from "./components/UKComponents/BecomePartnerPage/BecomePartnerPage";
import AboutPageUK from "./components/UKComponents/AboutPage/AboutPage";
import ContactUsPageUK from "./components/UKComponents/ContactPage/ContactPage";
import LifestyleLp from "./components/UKComponents/LifestyleLp/LifestyleLp";
import FaqPage from "./components/UKComponents/FaqPage/FaqPage";
import CorporatePage from "./components/UKComponents/CorporatePage/CorporatePage";

import LoginPage from "./components/LoginPage/LoginPage";
import OurTrainersPage from "./components/OurTrainersPage/OurTrainersPage";
import TrainerProfilePage from "./components/TrainerProfilePage/TrainerProfilePage";
import UserProfilePage from "./components/UserProfilePage/UserProfilePage";
import Homepage from "./components/Homepage/Homepage";
import Checkout from "./components/Checkout/Checkout";
import BookingContainer from "./components/Booking/Booking";
import BecomeCoachPage from "./components/BecomePartnerPage/BecomePartnerPage";
import BecomeMemberPage from "./components/BecomeMemberPage/BecomeMemberPage";
import AboutPage from "./components/AboutPage/AboutPage";
import ContactUsPage from "./components/ContactPage/ContactPage";
import PartnershipsPage from "./components/PartnershipsPage/PartnershipsPage";
import PillarsPage from "./components/PillarsPage/PillarsPage";
import ConfirmEmailPage from "./components/ConfirmEmailPage/ConfirmEmailPage";
import GymPage from "./components/GymPage/GymPage";
import TrustSafetyPage from "./components/TrustSafetyPage/TrustSafetyPage";
import TermsPage from "./components/TermsPage/TermsPage";
import PrivacyPage from "./components/PrivacyPage/PrivacyPage";
import CymbiotikaPage from "./components/CymbiotikaPage/CymbiotikaPage";
import LifestyleLP from "./components/LifestyleLP/LifestyleLP";
import LifestyleUpcomingPage from "./components/LifestyleLP/LifestyleUpcomingPage/LifestyleUpcomingPage";
import LifestyleCompletedPage from "./components/LifestyleLP/LifestyleCompletedPage/LifestyleCompletedPage";
import EventPage from "./components/EventPage/EventPage";
import OfferPage from "./components/OfferPage/OfferPage";
import SpiritualGangsterLandingPage from "./components/SpiritualGangsterLP/SpiritualGansterLP";
import SuccessPage from "./components/SuccessPage/SuccessPage";
import CancelPage from "./components/CancelPage/CancelPage";
import FAQPage from "./components/FAQPage/FAQPage";
import QuestionnairePage from "./components/QuestionnairePage/QuestionnairePage";
import CookieConsent from "./components/CookieConsent/CookieConsent";
import LoaderSpinnerIcon from "./icons/LoaderSpinnerIcon";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import RouteWithState from "./components/Route/Route";
import { RegionProvider } from "./context/RegionProvider";
import { PrevStateProvider } from "./context/PrevStateProvider";
import { ChatProvider } from "./context/ChatProvider";
import RewordsPage from "./components/RewordsPage/RewordsPage";

import GoogleOAuthProvider from "./components/GoogleOAuthProvider/GoogleOAuthProvider";
import UserInfoModal from "./components/UserInfoModal/UserInfoModal";
import VerifyEmailModal from "./components/VerifyEmailModal/VerifyEmailModal";

import useHeight from "./hooks/useHeight/useHeight";

import { selectRegionBasedOnUserLanguages } from "./utils/locale";
import "./App.scss";

import "regenerator-runtime/runtime.js";

import enGBMessages from "./locales/en-GB.json";
import enUSMessages from "./locales/en-US.json";

const messages = {
  "en-GB": enGBMessages,
  "en-US": enUSMessages,
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const fonts = [
  {
    cssSrc:
      "https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap",
  },
];

const options = {
  currency: "usd",
  // Fully customizable with appearance API.
  fonts,
};

export default function App() {
  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.
  const height = useHeight();

  return (
    <div id="app" style={{ /*position: 'fixed', */ height: `${height}px` }}>
      {process.env.NODE_ENV !== "development" ? <ServiceWorkerWrapper /> : null}
      <GoogleOAuthProvider
        clientId={process.env.REACT_APP_GOOGLE_GSI_CLIENT_ID}
      >
        <AppStateProvider>
          <RegionProvider>
            <MainApp />
          </RegionProvider>
        </AppStateProvider>
      </GoogleOAuthProvider>
      <CookieConsent />
    </div>
  );
}

function MainApp() {
  const { region } = useRegion();

  const { user, hasTriedToAutoLogin } = useAppState();

  const [routerKey, setRouterKey] = useState(`${region}-${Date.now()}`); // Initial router key

  // Dynamically set basename for the Router
  const basename = useMemo(() => (region === "US" ? "/us" : ""), [region]);

  // Update the URL and router key when region changes
  useEffect(() => {
    setTimeout(() => {
      const currentPath = window.location.pathname.replace("/us", ""); // Strip /us if present

      if (region === "US" && basename === "/us") {
        // Add /us to the path
        window.history.pushState(
          {},
          "",
          `/us${currentPath}${window.location.search}`
        );
        // Update the router key to force the Router to re-initialize
        setRouterKey(`${region}-${Date.now()}`);
      } else if (basename === "") {
        // Remove /us from the path
        window.history.pushState(
          {},
          "",
          `${currentPath}${window.location.search}`
        );
        // Update the router key to force the Router to re-initialize
        setRouterKey(`${region}-${Date.now()}`);
      }
    });

    // Update the document title based on the region
  }, [basename]);

  // SafeAreaProvider
  return (
    <Router basename={basename} key={routerKey}>
      <PrevStateProvider>
        <MultiLanguageApp />
      </PrevStateProvider>

      <SignUpStateProvider>
        <VerifyEmailModal showCloseButton />
      </SignUpStateProvider>
    </Router>
  );

  // </SafeAreaProvider>
}

function MultiLanguageApp() {
  const { region } = useRegion();

  const [selectedLanguage, setSelectedLanguage] = useState(
    getLocaleCode(region)
  );

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
  };

  useEffect(() => {
    setSelectedLanguage(`en-${region}`);
  }, [region]);

  return (
    <IntlProvider
      locale={selectedLanguage}
      messages={messages[selectedLanguage]}
    >
      <Switch>
        <Route exact path="/">
          {region === "GB" ? <HomepageUK /> : <Homepage />}
        </Route>

        <Route exact path="/gyms/:GymId">
          <GymPage />
        </Route>

        <Route exact path="/about">
          {region === "GB" ? <AboutPageUK /> : <AboutPage />}
        </Route>

        <Route exact path="/cymbiotika">
          <CymbiotikaPage />
        </Route>

        <Route exact path="/lifestyle">
          {region === "GB" ? <LifestyleLp /> : <LifestyleLP />}
        </Route>

        <Route exact path="/events">
          {region === "GB" ? <LifestyleLp /> : <LifestyleLP />}
        </Route>

        <Route path="/events/previous">
          <LifestyleCompletedPage />
        </Route>

        <Route path="/events/upcoming">
          <LifestyleUpcomingPage />
        </Route>

        <Route exact path="/events/:EventId">
          <EventPage />
        </Route>

        <Route exact path="/offers/:OfferId">
          <OfferPage />
        </Route>

        <Route exact path="/spiritual-gangster">
          <SpiritualGangsterLandingPage />
        </Route>

        <Route exact path="/become-a-member">
          <SignUpStateProvider>
            <BecomeMemberPage />
          </SignUpStateProvider>
        </Route>
        <Route exact path="/become-a-coach">
          <BecomeCoachPage />
        </Route>
        <Route exact path="/become-a-partner">
          {region === "GB" ? <BecomePartnerPage /> : <BecomeCoachPage />}
        </Route>
        <PrivateRoute path="/account">
          <Elements stripe={stripePromise} options={options}>
            <SignUpStateProvider>
              <ChatProvider>
                <UserProfilePage
                  selectedLanguage={selectedLanguage}
                  onSelectLanguage={handleLanguageChange}
                />
              </ChatProvider>
            </SignUpStateProvider>
          </Elements>
        </PrivateRoute>
        <RouteWithState
          exact
          path={[
            "/partners",
            "/partners/fitness",
            "/partners/spirit",
            "/partners/personal-training",
            "/partners/boxing",
            "/partners/kickboxing",
            "/partners/ballet",
            "/partners/ballet-fit",
            "/partners/running",
            "/partners/prepost-natal",
            "/partners/yoga",
            "/partners/pilates",
            "/partners/stretching",
            "/partners/meditation",
            "/partners/sound-baths",
            "/partners/sound-healing",
            "/partners/lifestyle",
            "/partners/lifestyle-coaching",
            "/partners/stress-management",
            "/partners/motivation",
            "/partners/motivational-coaching",
            "/partners/cymbiotika-certified",
            "/partners/spiritual-gangster",
            "/partners/spiritual-gangster-certified",
          ]}
        >
          <SignUpStateProvider>
            <OurTrainersPage />
          </SignUpStateProvider>
        </RouteWithState>
        <RouteWithState exact path="/partners/:Id">
          <SignUpStateProvider>
            <TrainerProfilePage />
          </SignUpStateProvider>
        </RouteWithState>
        <PrivateRoute exact path="/checkout">
          <Elements stripe={stripePromise} options={options}>
            <SignUpStateProvider>
              <BookingContainer>
                <Checkout />
              </BookingContainer>
            </SignUpStateProvider>
          </Elements>
        </PrivateRoute>
        <Route exact path="/contact">
          {region === "GB" ? <ContactUsPageUK /> : <ContactUsPage />}
        </Route>
        <Route exact path="/partnerships">
          <PartnershipsPage />
        </Route>
        <Route exact path="/pillars">
          <PillarsPage />
        </Route>

        <Route exact path="/rewords">
          <RewordsPage />
        </Route>
        <Route exact path="/trust-and-safety">
          <TrustSafetyPage />
        </Route>

        <Route exact path="/terms">
          <TermsPage />
        </Route>

        <Route exact path="/privacy">
          <PrivacyPage />
        </Route>

        <Route exact path="/faq">
          {region === "GB" ? <FaqPage /> : <FAQPage />}
        </Route>

        <Route exact path="/corporate">
          {region === "GB " ? <CorporatePage /> : <Redirect to="/" />}
        </Route>

        <Route exact path="/memberships/success">
          <SuccessPage />
        </Route>

        <Route exact path="/bookings/success">
          <SuccessPage />
        </Route>

        <Route exact path="/payments/cancel">
          <CancelPage />
        </Route>

        <Route exact path="/questionnaire">
          <QuestionnairePage />
        </Route>

        {/*<Route exact path="/password-reset">
              <PasswordResetPage />
            </Route>
            <Route exact path="/password-reset-instructions">
              <PasswordResetInstructionsSent />
            </Route>
            <Route exact path="/password-reset-new-password">
              <PasswordResetNewPassword />
            </Route>*/}
        <Route path="/login">
          <SignUpStateProvider>
            <LoginPage />
          </SignUpStateProvider>
        </Route>

        <Route path="/registration">
          <SignUpStateProvider>
            <LoginPage />
          </SignUpStateProvider>
        </Route>

        <Route path="/email-confirm">
          <ConfirmEmailPage />
        </Route>
        <Redirect to="/" />
      </Switch>
    </IntlProvider>
  );
}

const getLocaleCode = (region) => (region ? `en-${region}` : "en-GB");
