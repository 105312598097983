/* global Sentry */
import { useState, useCallback, useRef } from "react";
import * as Sentry from "@sentry/browser";

import { getAccessToken } from "../useAuth/useAuth";
import { makeCancelable, toQueryString } from "../../utils";

/**
 * Get events (for customer as well as for any user)
 */
export const fetchEvents = (
  customerId,
  status,
  pageableParams,
  region = "US",
  city
) => {
  const endpointUrl = `${process.env.REACT_APP_API_BASE_URL}/events/v1/events${
    customerId ? "/customer-events" : ""
  }?${toQueryString(
    Object.assign(
      {
        status,
        region,
        ...pageableParams,
      },
      customerId && { customerId },
      city && { city }
    )
  )}`;
  const accessToken = getAccessToken();

  const headers = {
    "content-type": "application/json",
    // Authorization: `Bearer ${accessToken}`,
  };

  if (customerId) {
    headers.Authorization = `Bearer ${accessToken}`;
  }

  const request = new Request(endpointUrl, {
    headers,
  });

  const cancelablePromise = makeCancelable(fetch(request));

  const requestPromise = cancelablePromise.promise.then(async (res) => {
    const jsonResponse = await res.json();

    if (!res.ok) {
      const error = new Error(
        jsonResponse.error ||
          `There was an error loading ${customerId ? "customer " : ""}events`
      );

      return Promise.reject(error);
    }

    return jsonResponse;
  });

  return {
    ...cancelablePromise,
    promise: requestPromise,
  };
};

export default function useEvent() {
  const [eventSummary, setEventSummary] = useState();
  const [customerMembership, setCustomerMembership] = useState(null);
  const [customerMembershipLoaded, setCustomerMembershipLoaded] =
    useState(false);
  const [offers, setOffers] = useState([]);
  // const [UpcomingEvents, setUpcomingEvents] = useState([]);
  const [startedEvents, setStartedEvents] = useState([]);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [completedEvents, setCompletedEvents] = useState([]);
  const [pastEventsLoaded, setPastEventsLoaded] = useState(false);
  const [futureEventsLoaded, setFutureEventsLoaded] = useState(false);

  const [pastEventsPageLoaded, setPastEventsPageLoaded] = useState(-1);
  const [hasMorePastEvents, setHasMorePastEvents] = useState(false);
  const [numberOfPastEvents, setNumberOfPastEvents] = useState(0);

  const [futureEventsPageLoaded, setFutureEventsPageLoaded] = useState(-1);
  const [hasMoreFutureEvents, setHasMoreFutureEvents] = useState(false);

  const [numberOfFutureEvents, setNumberOfFutureEvents] = useState(0);

  const [isPastEventsFetching, setIsPastEventsFetching] = useState(false);
  const [isUpcomingEventsFetching, setIsUpcomingEventsFetching] =
    useState(false);

  const pastEventsRef = useRef([]);
  const pastEventsPageLoadedRef = useRef(-1);
  const futureEventsRef = useRef([]);
  const futureEventsPageLoadedRef = useRef(-1);

  const loadStartedEvents = useCallback(() => {
    const endpointUrl = `${process.env.REACT_APP_API_BASE_URL}/events/v1/events/started-events?page=0&size=10&sort=start,asc`;

    // const token = getAccessToken();

    return fetch(endpointUrl, {
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`,
      },
    }).then(async (res) => {
      const jsonResponse = await res.json();

      if (!res.ok) {
        const getStartedEventsError = new Error(
          jsonResponse.message || "There was an error loading started events"
        );
        // getStartedEventsError.code = jsonResponse.error?.message;
        return Promise.reject(getStartedEventsError);
      }

      setStartedEvents(jsonResponse.data.content);

      return jsonResponse;
    });
    // .catch(err => setError(err));
  }, []);

  // Not used, because loadUpcomingEvents handles both for customers and not
  const getUpcomingEvents = useCallback(() => {
    const endpointUrl = `${process.env.REACT_APP_API_BASE_URL}/events/v1/events/upcoming-events?page=0&size=30&sort=start,asc`;

    // const token = getAccessToken();

    return fetch(endpointUrl, {
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`,
      },
    }).then(async (res) => {
      const jsonResponse = await res.json();

      if (!res.ok) {
        const getUpcomingEventsError = new Error(
          jsonResponse.message || "There was an error loading upcoming events"
        );
        // getUpcomingEventsError.code = jsonResponse.error?.message;
        return Promise.reject(getUpcomingEventsError);
      }

      setUpcomingEvents(jsonResponse.data.content);

      return jsonResponse;
    });
    // .catch(err => setError(err));
  }, []);

  // Not used, because loadCompletedEvents handles both for customers and not
  const getCompletedEvents = useCallback(() => {
    const endpointUrl = `${process.env.REACT_APP_API_BASE_URL}/events/v1/events/completed-events?page=0&size=4&sort=start,desc`;

    // const token = getAccessToken();

    return fetch(endpointUrl, {
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`,
      },
    }).then(async (res) => {
      const jsonResponse = await res.json();

      if (!res.ok) {
        const getCompletedEventsError = new Error(
          jsonResponse.message || "There was an error loading completed events"
        );
        // getCompletedEventsError.code = jsonResponse.error?.message;
        return Promise.reject(getCompletedEventsError);
      }

      setCompletedEvents(jsonResponse.data.content);

      return jsonResponse;
    });
    // .catch(err => setError(err));
  }, []);

  /**
   * Customer gets past events
   */
  const loadCompletedEvents = useCallback(
    async (customerId, status, pageableParams, region = "US") => {
      setIsPastEventsFetching(true);

      return fetchEvents(customerId, "COMPLETED", pageableParams, region)
        .promise.then((response) => {
          let page = pageableParams.page || 0;
          let pastEvents = page <= 0 ? [] : pastEventsRef.current;

          let pastEventsOffset = pastEvents.length || 0;
          let newPastEventsOffset =
            pastEventsOffset + response.data.content.length;

          if (pastEventsOffset < newPastEventsOffset) {
            // new items
            pastEvents = [
              ...pastEvents,
              ...response.data.content.slice(
                -(newPastEventsOffset - pastEventsOffset)
              ),
            ];
          }

          let hasMore = newPastEventsOffset < response.data.totalElements;
          page = hasMore ? page : pastEventsPageLoadedRef.current;

          pastEventsRef.current = pastEvents;

          // return Object.assign({}, state, { isCompletedFetching: false, isCompletedReceived: true }, { completed: { content: bookings, hasMore, page } });
          setHasMorePastEvents(hasMore);
          setPastEventsPageLoaded(page);
          pastEventsPageLoadedRef.current = page;
          setNumberOfPastEvents(response.data.totalElements);

          if (pastEvents) {
            setCompletedEvents(pastEvents);
            setPastEventsLoaded(true);
          }
        })
        .finally(() => {
          setIsPastEventsFetching(false);
        });
    },
    []
  );

  /**
   * Customer gets upcoming events
   */
  const loadUpcomingEvents = useCallback(
    (customerId, status, pageableParams, region = "US", city) => {
      setIsUpcomingEventsFetching(true);

      const res = fetchEvents(customerId, status, pageableParams, region, city);
      const requestPromise = res.promise
        .then((response) => {
          let page = pageableParams.page || 0;
          let futureEvents = page <= 0 ? [] : futureEventsRef.current;

          let futureEventsOffset = futureEvents.length || 0;
          let newFutureEventsOffset =
            futureEventsOffset + response.data.content.length;

          if (futureEventsOffset < newFutureEventsOffset) {
            // new items
            futureEvents = [
              ...futureEvents,
              ...response.data.content.slice(
                -(newFutureEventsOffset - futureEventsOffset)
              ),
            ];
          }

          let hasMore = newFutureEventsOffset < response.data.totalElements;
          page = hasMore ? page : futureEventsPageLoadedRef.current;

          futureEventsRef.current = futureEvents;

          // return Object.assign({}, state, { isCompletedFetching: false, isCompletedReceived: true }, { completed: { content: bookings, hasMore, page } });
          setHasMoreFutureEvents(hasMore);
          setFutureEventsPageLoaded(page);
          futureEventsPageLoadedRef.current = page;
          setNumberOfFutureEvents(response.data.totalElements);

          if (futureEvents) {
            setUpcomingEvents(futureEvents);
            setFutureEventsLoaded(true);
          }
        })
        .finally(() => {
          setIsUpcomingEventsFetching(false);
        });

      return {
        ...res,
        promise: requestPromise,
      };
    },
    []
  );

  /*
   * Customer gets an event
   */
  const loadEvent = useCallback((customerId, eventId) => {
    const endpointUrl = `${
      process.env.REACT_APP_API_BASE_URL
    }/events/v1/events/${eventId}${
      customerId ? `?customerId=${customerId}` : "/public"
    }`;
    const accessToken = getAccessToken();

    const headers = {
      "content-type": "application/json",
    };

    if (accessToken) {
      headers["Authorization"] = `Bearer ${accessToken}`;
    }

    const request = new Request(endpointUrl, {
      headers,
    });

    const cancelablePromise = makeCancelable(fetch(request));

    const requestPromise = cancelablePromise.promise.then(async (res) => {
      const jsonResponse = await res.json();

      if (!res.ok) {
        const error = new Error(
          jsonResponse.error || `There was an error loading event`
        );

        return Promise.reject(error);
      }

      setEventSummary(jsonResponse.data);

      return jsonResponse;
    });

    return {
      ...cancelablePromise,
      promise: requestPromise,
    };
  }, []);

  /**
   * Get offers
   */
  const loadOffers = useCallback(async (region = "US") => {
    const endpointUrl = `${process.env.REACT_APP_API_BASE_URL}/events/v1/offers?region=${region}`;
    // const accessToken = getAccessToken();

    return fetch(endpointUrl, {
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${accessToken}`,
      },
      method: "GET",
    }).then(async (res) => {
      const jsonResponse = await res.json();

      if (!res.ok) {
        const error = new Error(
          jsonResponse.message || "There was an error loading offers"
        );

        return Promise.reject(error);
      }

      setOffers(jsonResponse.data);

      return jsonResponse;
    });
  }, []);

  /*
   * Customer gets event membership
   */
  const loadCustomerMembership = useCallback(async (customerId) => {
    const endpointUrl = `${process.env.REACT_APP_API_BASE_URL}/payments/v1/payments?customerId=${customerId}&type=EVENT_MEMBERSHIP&sort=id,asc`;
    const accessToken = getAccessToken();

    return fetch(endpointUrl, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      method: "GET",
    }).then(async (res) => {
      const jsonResponse = await res.json();

      if (!res.ok) {
        const error = new Error(
          jsonResponse.message ||
            "There was an error loading customer membership"
        );

        // Even if it's not available, set to "true" anyway. At least we "tried"
        setCustomerMembershipLoaded(true);

        if (typeof Sentry !== "undefined") {
          Sentry.captureException(error, {
            extra: {
              response: {
                status: res.status,
                statusText: res.statusText,
                headers: res.headers,
                body: jsonResponse,
              },
            },
          });
        }

        return Promise.reject(error);
      }

      setCustomerMembershipLoaded(true);
      setCustomerMembership(jsonResponse.data.content[0]);

      return jsonResponse;
    });
  }, []);

  /*
   * Customer joins the event
   */
  const joinEvent = useCallback(async (eventId, customerId) => {
    const endpointUrl = `${process.env.REACT_APP_API_BASE_URL}/events/v1/events/${eventId}/join?customerId=${customerId}`;
    const accessToken = getAccessToken();

    return fetch(endpointUrl, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      method: "POST",
    }).then(async (res) => {
      const jsonResponse = await res.json();

      if (!res.ok) {
        const error = new Error(
          jsonResponse.message || "There was an error joining event"
        );

        return Promise.reject(error);
      }

      setEventSummary(jsonResponse.data);

      // Update the upcomingEvents array by finding the event with the matching ID
      setUpcomingEvents((prevEvents) =>
        prevEvents.map((event) => {
          if (event.id === eventId) {
            return { ...event, ...jsonResponse.data };
          }
          return event;
        })
      );

      return jsonResponse;
    });
  }, []);

  /*
   * Customer joins the event
   */
  const leaveEvent = useCallback(async (eventId, customerId) => {
    const endpointUrl = `${process.env.REACT_APP_API_BASE_URL}/events/v1/events/${eventId}/leave?customerId=${customerId}`;
    const accessToken = getAccessToken();

    return fetch(endpointUrl, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      method: "POST",
    }).then(async (res) => {
      const jsonResponse = await res.json();

      if (!res.ok) {
        const error = new Error(
          jsonResponse.message || "There was an error leaving event"
        );

        return Promise.reject(error);
      }

      setEventSummary(jsonResponse.data);

      // Update the upcomingEvents array by finding the event with the matching ID
      setUpcomingEvents((prevEvents) =>
        prevEvents.map((event) => {
          if (event.id === eventId) {
            return { ...event, ...jsonResponse.data };
          }
          return event;
        })
      );

      return jsonResponse;
    });
  }, []);

  /**
   * Customer cancels RSVP
   */
  const cancelEvent = useCallback(
    (eventId, customerId) => {
      const endpointUrl = `${process.env.REACT_APP_API_BASE_URL}/payments/v1/payments`;
      const accessToken = getAccessToken();

      const request = new Request(endpointUrl, {
        method: "DELETE",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          id: eventId,
          customerId,
          type: "EVENT",
        }),
      });

      const cancelablePromise = makeCancelable(fetch(request));

      const requestPromise = cancelablePromise.promise.then(async (res) => {
        const jsonResponse = await res.json();

        if (!res.ok) {
          const error = new Error(
            jsonResponse.message || `There was an error cancelling event`
          );

          return Promise.reject(error);
        }

        // Should we update `currentCapacity`?
        setEventSummary({
          ...eventSummary,
          ...jsonResponse.data,
          joined: false,
        });

        const upcomingEventToUpdate = upcomingEvents.find(
          (val) => val.id === eventSummary.id
        );

        if (upcomingEventToUpdate) {
          upcomingEventToUpdate.capacity = jsonResponse.data.capacity;
          upcomingEventToUpdate.currentCapacity =
            jsonResponse.data.currentCapacity;
          upcomingEventToUpdate.status = jsonResponse.data.status;
          upcomingEventToUpdate.joined = false;
        }

        return jsonResponse;
      });

      return {
        ...cancelablePromise,
        promise: requestPromise,
      };
    },
    [eventSummary, upcomingEvents]
  );

  /**
   * Customer cancels the membership
   */
  const cancelEventMembership = useCallback(
    (customerId, membershipId, reasons) => {
      const endpointUrl = `${process.env.REACT_APP_API_BASE_URL}/payments/v1/payments`;
      const accessToken = getAccessToken();

      const request = new Request(endpointUrl, {
        method: "DELETE",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          id: membershipId,
          customerId,
          type: "EVENT_MEMBERSHIP",
          reason: reasons.join(", "),
        }),
      });

      const cancelablePromise = makeCancelable(fetch(request));

      const requestPromise = cancelablePromise.promise.then(async (res) => {
        const jsonResponse = await res.json();

        if (!res.ok) {
          const error = new Error(
            jsonResponse.message ||
              `There was an error cancelling event membership`
          );

          return Promise.reject(error);
        }

        setCustomerMembership(jsonResponse.data);

        return jsonResponse;
      });

      return {
        ...cancelablePromise,
        promise: requestPromise,
      };
    },
    []
  );

  /**
   * Customer pauses the membership
   */
  const pauseEventMembership = useCallback((customerId, membershipId) => {
    const endpointUrl = `${process.env.REACT_APP_API_BASE_URL}/payments/v1/payments`;
    const accessToken = getAccessToken();

    return fetch(endpointUrl, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      method: "PATCH",
      body: JSON.stringify({
        id: membershipId,
        customerId,
        type: "EVENT_MEMBERSHIP",
        action: "PAUSE",
      }),
    }).then(async (res) => {
      const jsonResponse = await res.json();

      if (!res.ok) {
        const error = new Error(
          jsonResponse.message ||
            "There was an error pausing customer membership"
        );

        return Promise.reject(error);
      }

      setCustomerMembership(jsonResponse.data);

      return jsonResponse;
    });
  }, []);

  /**
   * Customer re-enables the membership
   */
  const unpauseEventMembership = useCallback((customerId, membershipId) => {
    const endpointUrl = `${process.env.REACT_APP_API_BASE_URL}/payments/v1/payments`;
    const accessToken = getAccessToken();

    return fetch(endpointUrl, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      method: "POST",
      body: JSON.stringify({
        id: membershipId,
        customerId,
        type: "EVENT_MEMBERSHIP",
        action: "UNPAUSE",
      }),
    }).then(async (res) => {
      const jsonResponse = await res.json();

      if (!res.ok) {
        const error = new Error(
          jsonResponse.message ||
            "There was an error pausing customer membership"
        );

        return Promise.reject(error);
      }

      setCustomerMembership(jsonResponse.data);

      return jsonResponse;
    });
  }, []);

  /**
   * Prevent past events caching
   */
  const clearCompletedEventsLoaded = useCallback(() => {
    setPastEventsLoaded(false);
  }, []);

  /**
   * Prevent future events caching
   */
  const clearUpcomingEventsLoaded = useCallback(() => {
    setFutureEventsLoaded(false);
  }, []);

  // const clearUpcomingEventsReceive = useCallback(() => {
  //   // setUpcomingEventsPage(undefined);
  //   // setHasMoreUpcomingEvents(false);
  //   // setUpcomingEvents([]);
  // }, []);

  const clearCustomerMembership = useCallback(() => {
    setCustomerMembership(null);
    setCustomerMembershipLoaded(false); // Is it okay to do it here???
  }, []);

  const clearCustomerMembershipLoaded = useCallback(() => {
    setCustomerMembershipLoaded(false);
  }, []);

  return {
    eventSummary,
    startedEvents,
    upcomingEvents,
    completedEvents,
    customerMembership,
    customerMembershipLoaded,
    offers,
    pastEventsLoaded,
    futureEventsLoaded,
    numberOfPastEvents,
    numberOfFutureEvents,
    pastEventsPageLoaded,
    futureEventsPageLoaded,
    hasMorePastEvents,
    hasMoreFutureEvents,
    isPastEventsFetching,
    isUpcomingEventsFetching,
    loadEvent,
    loadStartedEvents,
    loadUpcomingEvents,
    loadCompletedEvents,
    //loadCustomerUpcomingEvents,
    //loadCustomerCompletedEvents,
    loadCustomerMembership,
    pauseEventMembership,
    unpauseEventMembership,
    loadOffers,
    joinEvent,
    leaveEvent,
    cancelEventMembership,
    cancelEvent,
    clearUpcomingEventsLoaded,
    clearCompletedEventsLoaded,
    clearCustomerMembership,
    clearCustomerMembershipLoaded,
  };
}
