import React from "react";

export default function UploadIcon({ className, size }) {
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M5 12v7.2c0 .477.184.935.513 1.273.328.337.773.527 1.237.527h10.5c.464 0 .91-.19 1.237-.527.329-.338.513-.796.513-1.273V12M16 7l-4-4-4 4M12 3v12"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
