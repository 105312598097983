import { useState, useCallback } from "react";

import { makeCancelable, toQueryString } from "../../utils";
import { getAccessToken } from "../useAuth/useAuth";
import { PAGE_SIZE } from "../../constants";

/**
 * Customer gets bookings
 */
export const fetchBookings = (
  customerId,
  status,
  pageableParams,
  region = "US"
) => {
  const endpointUrl = `${
    process.env.REACT_APP_API_BASE_URL
  }/bookings/v1/bookings?${toQueryString({
    customerId,
    status,
    region,
    ...pageableParams,
  })}`;
  const accessToken = getAccessToken();

  const request = new Request(endpointUrl, {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const cancelablePromise = makeCancelable(fetch(request));

  const requestPromise = cancelablePromise.promise.then(async (res) => {
    const jsonResponse = await res.json();

    if (!res.ok) {
      const error = new Error(
        jsonResponse.error || `There was an error loading bookings`
      );

      return Promise.reject(error);
    }

    return jsonResponse;
  });

  return {
    ...cancelablePromise,
    promise: requestPromise,
  };
};

export default function useBooking() {
  const [confirmedBooking, setConfirmedBooking] = useState(null);
  const [bookings, setBookings] = useState([]);
  const [pastBookings, setPastBookings] = useState([]);
  const [pastBookingsPage, setPastBookingsPage] = useState();
  const [booking, setBooking] = useState(null);
  const [hasMorePastBookings, setHasMorePastBookings] = useState(false);
  const [isPastBookingsFetching, setIsPastBookingsFetching] = useState(false);

  /**
   * Customer books a session
   */
  const createBooking = useCallback((params, region = "US") => {
    const endpointUrl = `${process.env.REACT_APP_API_BASE_URL}/bookings/v1/bookings`;
    const accessToken = getAccessToken();

    const request = new Request(endpointUrl, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(params),
    });

    const cancelablePromise = makeCancelable(fetch(request));

    const requestPromise = cancelablePromise.promise.then(async (res) => {
      const jsonResponse = await res.json();

      if (!res.ok) {
        const error = new Error(
          jsonResponse.message || `There was an error creating booking`
        );

        return Promise.reject(error);
      }

      setBooking(jsonResponse.data);

      return jsonResponse;
    });

    return {
      ...cancelablePromise,
      promise: requestPromise,
    };
  }, []);

  /**
   * Customer gets bookings
   */
  const loadBookings = useCallback(
    (customerId, status, pageableParams, region) => {
      return fetchBookings(
        customerId,
        status,
        pageableParams,
        region
      ).promise.then((response) => {
        setBookings([...response.data.content]);
      });
    },
    []
  );

  /**
   * Customer gets past bookings
   * Booking statuses: CREATED, ACTIVE, COMPLETED, FAILED, CANCELLED
   */
  const loadPastBookings = useCallback(
    (customerId, status, pageableParams, region) => {
      setIsPastBookingsFetching(true);

      return fetchBookings(customerId, status, pageableParams, region)
        .promise.then((response) => {
          let lastPastBookings = [...pastBookings];
          let hasMore = false;

          let page = pageableParams.page;
          // let size = pageableParams.size;

          let stateBookingsOffset = pastBookings.length;
          let newBookingsOffset =
            stateBookingsOffset + response.data.content.length;

          if (
            stateBookingsOffset < newBookingsOffset &&
            pastBookingsPage !== page
          ) {
            // new items
            lastPastBookings = lastPastBookings.concat(
              response.data.content.slice(
                -(newBookingsOffset - stateBookingsOffset)
              )
            );
            hasMore = response.data.content.length === PAGE_SIZE;
          }

          page = hasMore ? page : pastBookingsPage;

          setPastBookings(lastPastBookings);
          setHasMorePastBookings(hasMore);
          setPastBookingsPage(page);
        })
        .finally(() => {
          setIsPastBookingsFetching(false);
        });
    },
    [pastBookings, pastBookingsPage]
  );

  const loadBooking = useCallback((customerId, bookingId) => {
    const endpointUrl = `${process.env.REACT_APP_API_BASE_URL}/bookings/v1/bookings/${bookingId}`;
    const accessToken = getAccessToken();

    const request = new Request(endpointUrl, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const cancelablePromise = makeCancelable(fetch(request));

    const requestPromise = cancelablePromise.promise.then(async (res) => {
      const jsonResponse = await res.json();

      if (!res.ok) {
        const error = new Error(
          jsonResponse.error || `There was an error loading booking`
        );

        return Promise.reject(error);
      }

      setBooking(jsonResponse.data);

      return jsonResponse;
    });

    return {
      ...cancelablePromise,
      promise: requestPromise,
    };
  }, []);

  /**
   * Customer cancels the session
   */
  // const cancelBooking = useCallback((bookingId, customerId) => {
  //   const endpointUrl = `${process.env.REACT_APP_API_BASE_URL}/bookings/v1/bookings/${bookingId}/cancel?customerId=${customerId}`;
  //   const accessToken = getAccessToken();
  //
  //   const request = new Request(endpointUrl, {
  //     method: "POST",
  //     headers: {
  //       "content-type": "application/json",
  //       Authorization: `Bearer ${accessToken}`,
  //     },
  //     // body: JSON.stringify(params),
  //   });
  //
  //   const cancelablePromise = makeCancelable(fetch(request));
  //
  //   const requestPromise = cancelablePromise.promise.then(async (res) => {
  //     const jsonResponse = await res.json();
  //
  //     if (!res.ok) {
  //       const error = new Error(
  //         jsonResponse.message || `There was an error cancelling booking`
  //       );
  //
  //       return Promise.reject(error);
  //     }
  //
  //     setBooking(jsonResponse.data);
  //
  //     return jsonResponse;
  //   });
  //
  //   return {
  //     ...cancelablePromise,
  //     promise: requestPromise,
  //   };
  // }, []);

  /**
   * Customer cancels the session
   */
  const cancelBooking = useCallback((bookingId, customerId) => {
    const endpointUrl = `${process.env.REACT_APP_API_BASE_URL}/payments/v1/payments/booking?customerId=${customerId}&bookingId=${bookingId}`;
    const accessToken = getAccessToken();

    const request = new Request(endpointUrl, {
      method: "DELETE",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      // body: JSON.stringify(params),
    });

    const cancelablePromise = makeCancelable(fetch(request));

    const requestPromise = cancelablePromise.promise.then(async (res) => {
      const jsonResponse = await res.json();

      if (!res.ok) {
        const error = new Error(
          jsonResponse.message || `There was an error cancelling booking`
        );

        return Promise.reject(error);
      }

      setBooking((prevBooking) => ({
        ...prevBooking,
        status: "CANCELLED",
      }));

      return jsonResponse;
    });

    return {
      ...cancelablePromise,
      promise: requestPromise,
    };
  }, []);

  /**
   * Customer loads bookings without  ratings
   */
  const loadBookingsWithoutRating = useCallback(
    async (customerId, region = "GB") => {
      const endpointUrl = `${process.env.REACT_APP_API_BASE_URL}/bookings/v1/bookings/without-rating?customerId=${customerId}&sort=start,asc&region=${region}`;
      const accessToken = getAccessToken();

      return fetch(endpointUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        method: "GET",
      }).then(async (res) => {
        const jsonResponse = await res.json();

        if (!res.ok) {
          const error = new Error(
            jsonResponse.message ||
              "There was an error loading bookings without rating"
          );

          return Promise.reject(error);
        }

        return jsonResponse;
      });
    },
    []
  );

  const clearPastBookingsReceive = useCallback(() => {
    setPastBookingsPage(undefined);
    setHasMorePastBookings(false);
    setPastBookings([]);
  }, []);

  return {
    createBooking,
    confirmedBooking,
    setConfirmedBooking,
    loadBookings,
    loadPastBookings,
    loadBooking,
    cancelBooking,
    bookings,
    pastBookings,
    booking,
    pastBookingsPage,
    hasMorePastBookings,
    loadBookingsWithoutRating,
    isPastBookingsFetching,
    clearPastBookingsReceive,
  };
}
