import React from "react";

export default function GiftIcon({ className, size }) {
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="12" cy="12" r="9" fill="url(#paint0_linear_17320_842)" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.2656 9.71582H14.3376C14.4759 9.61494 14.5947 9.51472 14.6776 9.4249C15.1694 8.89707 15.1694 8.03796 14.6776 7.51013C14.1998 6.99597 13.3674 6.99727 12.8903 7.51013C12.6258 7.79325 11.925 8.94588 12.0219 9.71582H11.9781C12.0743 8.94588 11.3736 7.79325 11.1097 7.51013C10.632 6.99727 9.79955 6.99727 9.32241 7.51013C8.83125 8.03796 8.83125 8.89707 9.3218 9.4249C9.40528 9.51472 9.52411 9.61494 9.66244 9.71582H7.73438C7.39861 9.71582 7.125 10.0081 7.125 10.3667V11.3429C7.125 11.5226 7.2615 11.6683 7.42969 11.6683H11.3906V10.3667H12.6094V11.6683H16.5703C16.7385 11.6683 16.875 11.5226 16.875 11.3429V10.3667C16.875 10.0081 16.602 9.71582 16.2656 9.71582ZM11.3547 9.69435C11.3547 9.69435 11.3291 9.71582 11.2419 9.71582C10.8209 9.71582 10.0171 9.24852 9.75445 8.96606C9.49913 8.69141 9.49913 8.24363 9.75445 7.96897C9.87816 7.8362 10.0421 7.76331 10.2164 7.76331C10.39 7.76331 10.554 7.8362 10.6777 7.96897C11.0884 8.41024 11.4967 9.53749 11.3547 9.69435ZM12.7575 9.71582C12.6709 9.71582 12.6453 9.695 12.6453 9.69435C12.5033 9.53749 12.9116 8.41024 13.3223 7.96897C13.5679 7.70408 13.9975 7.70278 14.2455 7.96897C14.5015 8.24363 14.5015 8.69141 14.2455 8.96606C13.9829 9.24852 13.1791 9.71582 12.7575 9.71582ZM7.73438 12.3193V16.2243C7.73438 16.5836 8.00798 16.8752 8.34375 16.8752H11.3906V12.3193H7.73438ZM12.608 16.8752V12.3193H16.2643V16.2243C16.2643 16.5836 15.9913 16.8752 15.6549 16.8752H12.608Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_17320_842"
          x1="3"
          y1="3.03896"
          x2="13.6864"
          y2="26.6139"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFC397" />
          <stop offset="1" stop-color="#FF6C4D" />
        </linearGradient>
      </defs>
    </svg>
  );
}
