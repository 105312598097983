/**
 * Pause Membership Modal
 *
 * @author Vladimir Shishlyannikov
 * @created 2024/12/16
 * @copyright 2024-present iWoo Ltd. All rights reserved.
 */

import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import moment from "moment";
import { getMomentWithTz } from "../../../utils/date";

// Components
import Button from "../../Button/Button";

// Icons
import CloseIcon from "../../../icons/CloseIcon";

// Utils
import { modalStyles } from "../../../constants";

export const PauseMembershipModal = ({
  isFetching,
  currentPeriodEnd,
  resumeAt,
  open,
  paused,
  region,
  onClose,
  onMembershipPause,
}) => {
  const defaultCustomStyle = Object.assign(
    {
      content: {
        ...modalStyles.default.content,
        width: "358px",
      },
    },
    { overlay: { ...modalStyles.default.overlay, zIndex: 1060 } }
  );

  const closeModal = useCallback(() => {
    onClose && onClose();
  }, []);

  const renewalDate = getMomentWithTz(
    moment.utc(currentPeriodEnd).toDate(),
    region
  ); //moment.utc(currentPeriodEnd).add(1, 'month');
  const resumeAtDate = getMomentWithTz(moment.utc(resumeAt).toDate(), region);
  const dateNow = getMomentWithTz(new Date(), region);
  const afterRenewalDate = dateNow.isAfter(renewalDate);

  return (
    <Modal
      appElement={/*__CLIENT__ && */ document.getElementById("#app")}
      isOpen={open}
      onRequestClose={closeModal}
      style={defaultCustomStyle}
      contentLabel="Pause Membership"
    >
      <div className="modal-pause-rsvp tbk-rounded-2xl tbk-bg-basic-white">
        <h3
          className={`tbk-text-h3-subtitle tbk-mt-0 tbk-mb-2 tbk-text-center tbk-text-blue-dark`}
        >
          {paused ? "Re-Enable Subscription" : "Pause Subscription for 1 month"}
        </h3>
        <p className="tbk-text-main tbk-mb-4 tbk-text-center tbk-text-blue-grey">
          {paused ? (
            <span>
              Your subscription will be re-activated now. Next renewal:{" "}
              {afterRenewalDate
                ? resumeAtDate.format("MMMM DD, YYYY")
                : renewalDate.format("MMMM DD, YYYY")}
              .
            </span>
          ) : (
            <span>
              Your subscription will be active until{" "}
              {renewalDate.format("MMMM DD, YYYY")} and then will be paused for
              1 month. Next renewal:{" "}
              {renewalDate.add(1, "month").format("MMMM DD, YYYY")}
              .
              <br />
              <br />
              You may re-enable your subscription any time before{" "}
              {renewalDate.format("MMMM DD, YYYY")}. You will not be charged
              during the time your membership is paused.
            </span>
          )}
        </p>
        <Button
          variant="secondary"
          disabled={isFetching}
          showLoader={isFetching}
          className="tbk-mb-2 tbk-w-full"
          onClick={onMembershipPause}
        >
          {paused ? "Re-Enable Subscription" : "Pause Subscription"}
        </Button>
        <Button
          variant="secondary"
          disabled={isFetching}
          showLoader={isFetching}
          className="tbk-mb-2 tbk-w-full"
          onClick={closeModal}
        >
          {paused ? "Keep it Paused" : "Keep Subscription"}
        </Button>
      </div>
    </Modal>
  );
};

PauseMembershipModal.displayName = "PauseMembershipModal";
PauseMembershipModal.propTypes = {
  open: PropTypes.bool.isRequired,
  isTrial: PropTypes.bool,
  region: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onMembershipPause: PropTypes.func.isRequired,
};
PauseMembershipModal.defaultProps = {
  // open: false,
  region: "US",
};

export default PauseMembershipModal;
