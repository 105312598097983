import React from "react";
import moment from "moment";
import ArrowRightIcon from "../../../icons/ArrowRightIcon";
import CheckOIcon from "../../../icons/CheckOIcon";
// import CheckNiceIcon from "../../../icons/CheckNiceIcon";
// import CheckNiceIcon from "../../../icons/CheckNiceOIcon";
import CheckNiceIcon from "../../../icons/CheckboxOIcon";
import { Link } from "react-router-dom";

const AchievementCard = ({
  logoUrl,
  title,
  condition,
  description,
  points,
  pointsTitle,
  received,
}) => {
  return (
    <div className="tbk-relative tbk-cursor-pointer tbk-rounded-2xl tbk-px-2 tbk-pt-2 tbk-pb-3 tbk-shadow-trube">
      <div className="tbk-absolute tbk-top-1 tbk-left-1">
        {received || true ? (
          <CheckNiceIcon width={24} height={24} className="tbk-text-green" />
        ) : null}
      </div>
      <img
        src={logoUrl}
        alt={title}
        className="tbk-mx-auto tbk-mb-2 tbk-h-7 tbk-w-7 tbk-shrink-0"
      />
      <div className="tbk-mt-4_ tbk-relative tbk-flex tbk-items-center tbk-justify-center">
        <div className="tbk-min-w-0 tbk-overflow-hidden tbk-text-center">
          <h3
            className={`tbk-text-h3-subtitle lg:tbk-text-title-bold tbk-mb-0.5 tbk-mb-0.5 tbk-text-center ${
              received ? "tbk-text-primary" : "tbk-text-blue-grey"
            } tbk-overflow-hidden tbk-text-ellipsis tbk-whitespace-nowrap`}
          >
            {title}
          </h3>
          <div className="tbk-text-secondary tbk-mb-0.5 tbk-overflow-hidden tbk-text-ellipsis tbk-whitespace-nowrap tbk-text-center tbk-text-grey">
            <span className="tbk-text-green">+{points / 100}</span> Points per
            Class
          </div>

          <div className="tbk-text-main tbk-overflow-hidden tbk-text-ellipsis tbk-whitespace-nowrap tbk-text-center tbk-text-primary">
            {condition}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AchievementCard;
